import React from "react";

const AboutPage = () => {
  return (
    <div className="card bg-base-100 shadow-xl p-8 space-y-6 mt-4 ">
      {/* Vizyon ve Misyon */}
      <section>
        <h1 className="text-3xl font-bold text-left mb-8 mt-4">
          Golden Keys Vizyonu
        </h1>
        <p className="text-gray-700">
          Faaliyet gösterdiği gayrimenkul sektöründe doğruluk, dürüstlük ve
          güvenilirlik ilkelerinden ödün vermeden, teknoloji ile entegre bir
          şekilde çalişmalarına devam ederek, hizmet verdiği tüm bölgelerde
          değerlerini koruyarak örnek ve lider olma vizyonunu taşımaktadır.
        </p>
      </section>
      <section>
        <h1 className="text-3xl font-bold text-left mb-8 mt-4">
          Golden Keys Misyonu
        </h1>
        <p className="text-gray-700">
          Gayrimenkul sektöründe, beklenti ve ihtiyaçlara uygun çözümler üretip
          pazarlama, satış kiralama ve sonrasında koşulsuz müşteri memnuniyeti
          bilinciyle, hızlı ve güvenilir hizmetler sunmak amacındadır.
        </p>
      </section>

      {/* Kurucu Bilgileri */}
      <section className="flex flex-col lg:flex-row items-center lg:items-start gap-6 ">
        <figure className="w-auto lg:w-96">
          <img
            src="indir.jpeg"
            alt="Kurucu Üye"
            className="object-cover w-full h-full mt-4"
          />
        </figure>
        <div className="lg:w-2/3 space-y-2 mt-4">
          <h2 className="text-2xl font-bold text-left">Ali Yetgin</h2>
          <p className="text-lg text-gray-600">Yönetim Kurulu Başkanı</p>
          <div className="mt-4 space-y-2">
            <p className="flex items-center text-gray-700">
              <span className="material-icons text-left mr-2">Telefon:</span>
              0(532) 120 69 09
            </p>
            <a href="tel:+905321206909" className="btn btn-primary  btn-sm">
              Ara
            </a>
            <section>
              <h1 className="text-3xl font-bold text-left mb-4 mt-8">
                Hakkında
              </h1>
              <p className="text-gray-700">
                1988 yılında İzmir’in Bergama ilçesinde doğdum. Eğitim hayatımı
                tamamladıktan sonra ticaret sektörüne adım attım. 20 yılı aşkın
                ticari deneyimimin ardından, gayrimenkul sektörüne yenilikçi ve
                modern bir bakış açısı kazandırmak amacıyla Golden Keys Real
                Estate şirketini kurdum. Türkiye’nin gayrimenkul sektöründeki
                güvenilir ve öncü markalarından biri olma hedefiyle, profesyonel
                ve müşteri odaklı hizmet anlayışıyla faaliyet göstermeye devam
                ediyorum.
              </p>
            </section>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutPage;
