const config = {
  development: {
    apiUrl: process.env.REACT_APP_API_URL_DEVELOPMENT,
  },
  production: {
    apiUrl: process.env.REACT_APP_API_URL_PRODUCTION,
  }
};

const environment = process.env.NODE_ENV || 'development';

// Fallback to development API if env variables are not set
const apiUrl = config[environment].apiUrl || config.development.apiUrl;

export { apiUrl };