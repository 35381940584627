import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Input from "react-phone-number-input/input";
import { motion, AnimatePresence } from "framer-motion";
import { submitFranchiseForm } from "../services/franchiseService";

const FranchiseForm = ({ closeModal }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState(null);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      birthDate: "",
      education: "",
      city: "",
      district: "",
      street: "",
      sectorExperience: "",
      brandExperience: "",
      importantFactor: "",
      budget: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Adınızı giriniz"),
      lastName: Yup.string().required("Soyadınızı giriniz"),
      phone: Yup.string().required("İletişim numaranızı giriniz"),
      email: Yup.string().email("Geçersiz e-posta adresi").required("E-posta adresinizi giriniz"),
      birthDate: Yup.date().required("Doğum tarihinizi giriniz"),
      education: Yup.string().required("Eğitim durumunuzu seçiniz"),
      city: Yup.string().required("İl bilgisini giriniz"),
      district: Yup.string().required("İlçe bilgisini giriniz"),
      street: Yup.string().required("Mahalle/Sokak bilgisini giriniz"),
    }),
    onSubmit: async (values) => {
      setIsSubmitting(true);
      setError(null);
      try {
        // Extract the country code and format the phone number
        const formattedPhone = values.phone.replace("+90", "");
        const dataToSubmit = { ...values, phone: formattedPhone };
        
        await submitFranchiseForm(dataToSubmit);
        setIsSubmitted(true);
        // Wait a bit before closing to show success state
        setTimeout(() => {
          if (closeModal) closeModal();
        }, 1500);
      } catch (err) {
        setError(err.message || 'Başvuru gönderilirken bir hata oluştu. Lütfen tekrar deneyiniz.');
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  const modalVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.3,
        type: "spring",
        stiffness: 260,
        damping: 20
      }
    },
    exit: {
      opacity: 0,
      scale: 0.8,
      transition: {
        duration: 0.3
      }
    }
  };

  const formControlVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5
      }
    }
  };

  const staggerContainer = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const successVariants = {
    hidden: { scale: 0.8, opacity: 0 },
    visible: {
      scale: 1,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 20
      }
    }
  };

  return (
    <AnimatePresence>
      <motion.div
        className="modal-container"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <motion.div
          className="modal-content"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          <form onSubmit={formik.handleSubmit} className="card bg-base-100">
            <motion.button
              type="button"
              onClick={closeModal}
              className="absolute top-2 right-2 text-xl text-gray-500 hover:text-black"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              <span className="font-bold">✖</span>
            </motion.button>

            <motion.h2
              className="font-bold text-gold text-center text-lg lg:text-2xl"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
            >
              GOLDEN KEYS
            </motion.h2>
            <motion.h2
              className="font-bold text-gold mb-8 text-center text-lg lg:text-2xl"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3 }}
            >
              Franchise Başvuru Formu
            </motion.h2>

            <motion.div
              variants={staggerContainer}
              initial="hidden"
              animate="visible"
            >
              {error && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  className="toast toast-top toast-end"
                >
                  <div className="alert alert-error mb-4">
                    {error}
                  </div>
                </motion.div>
              )}

              {isSubmitted ? (
                <motion.div
                  variants={successVariants}
                  initial="hidden"
                  animate="visible"
                  className="text-center py-10"
                >
                  <motion.div
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    transition={{ delay: 0.2, type: "spring" }}
                    className="text-6xl mb-4"
                  >
                    ✓
                  </motion.div>
                  <h3 className="text-2xl font-bold text-gold mb-2">Başvurunuz Alındı!</h3>
                  <p className="text-gray-600">En kısa sürede sizinle iletişime geçeceğiz.</p>
                </motion.div>
              ) : (
                <>
                  {/* Existing form fields wrapped in motion.div */}
                  <motion.div variants={formControlVariants} className="form-control">
                    {/* First Name Input */}
                    <label className="label">
                      <span className="label-text">Adınız</span>
                    </label>
                    <input
                      type="text"
                      name="firstName"
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Adınız"
                      className={`input input-bordered w-full ${formik.touched.firstName && formik.errors.firstName ? 'input-error' : ''
                        }`}
                      required
                    />
                    {formik.touched.firstName && formik.errors.firstName ? (
                      <motion.div
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: "auto" }}
                        exit={{ opacity: 0, height: 0 }}
                        className="text-red-500 text-sm text-left"
                      >
                        {formik.errors.firstName}
                      </motion.div>
                    ) : null}
                  </motion.div>

                  <motion.div variants={formControlVariants} className="form-control">
                    <label className="label">
                      <span className="label-text">Soyadınız</span>
                    </label>
                    <input
                      type="text"
                      name="lastName"
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Soyadınız"
                      className={`input input-bordered w-full ${formik.touched.lastName && formik.errors.lastName ? 'input-error' : ''
                        }`}
                      required
                    />
                    {formik.touched.lastName && formik.errors.lastName ? (
                      <motion.div
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: "auto" }}
                        exit={{ opacity: 0, height: 0 }}
                        className="text-red-500 text-sm text-left"
                      >
                        {formik.errors.lastName}
                      </motion.div>
                    ) : null}
                  </motion.div>

                  <motion.div variants={formControlVariants} className="form-control mb-4">
                    <label className="label">
                      <span className="label-text">Telefon Numarası</span>
                    </label>
                    <div className="relative">
                      <div className="absolute top-1 left-1 dropdown dropdown-start border-r-[1px]">
                        <div
                          tabIndex={0}
                          role="button"
                          className="btn btn-ghost btn-sm w-[64px] p-0"
                        >
                          +90
                          <svg
                            className="w-4 h-4 ml-1"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M19 9l-7 7-7-7"
                            ></path>
                          </svg>
                        </div>
                      </div>
                      <Input
                        country="TR"
                        value={formik.values.phone}
                        onChange={(value) => formik.setFieldValue("phone", value)}
                        onBlur={formik.handleBlur}
                        placeholder="Telefon Numaranızı Giriniz"
                        maxLength="13"
                        className={`input input-bordered w-full pl-[74px] ${formik.touched.phone && formik.errors.phone ? 'input-error' : ''
                          }`}
                        required
                      />
                      {formik.touched.phone && formik.errors.phone ? (
                        <motion.div
                          initial={{ opacity: 0, height: 0 }}
                          animate={{ opacity: 1, height: "auto" }}
                          exit={{ opacity: 0, height: 0 }}
                          className="text-red-500 text-sm text-left"
                        >
                          {formik.errors.phone}
                        </motion.div>
                      ) : null}
                    </div>
                  </motion.div>

                  <motion.div variants={formControlVariants} className="form-control mb-4">
                    <label className="label">
                      <span className="label-text">E-Posta</span>
                    </label>
                    <input
                      type="email"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="E-posta Adresinizi Giriniz"
                      className={`input input-bordered w-full ${formik.touched.email && formik.errors.email ? 'input-error' : ''
                        }`}
                      required
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <motion.div
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: "auto" }}
                        exit={{ opacity: 0, height: 0 }}
                        className="text-red-500 text-sm text-left"
                      >
                        {formik.errors.email}
                      </motion.div>
                    ) : null}
                  </motion.div>

                  <motion.div variants={formControlVariants} className="form-control mb-4">
                    <label className="label">
                      <span className="label-text">Doğum Tarihi</span>
                    </label>
                    <input
                      type="date"
                      name="birthDate"
                      value={formik.values.birthDate}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={`input input-bordered w-full ${formik.touched.birthDate && formik.errors.birthDate ? 'input-error' : ''
                        }`}
                      required
                    />
                    {formik.touched.birthDate && formik.errors.birthDate ? (
                      <motion.div
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: "auto" }}
                        exit={{ opacity: 0, height: 0 }}
                        className="text-red-500 text-sm text-left"
                      >
                        {formik.errors.birthDate}
                      </motion.div>
                    ) : null}
                  </motion.div>

                  <motion.div variants={formControlVariants} className="form-control mb-4">
                    <label className="label">
                      <span className="label-text">Eğitim Durumu</span>
                    </label>
                    <select
                      name="education"
                      value={formik.values.education}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={`select select-bordered w-full ${formik.touched.education && formik.errors.education ? 'input-error' : ''
                        }`}
                      required
                    >
                      <option value="">Eğitim Durumunuzu Seçiniz</option>
                      <option value="İlkokul">İlkokul</option>
                      <option value="Ortaokul">Ortaokul</option>
                      <option value="Lise">Lise</option>
                      <option value="Üniversite">Üniversite</option>
                      <option value="Yüksek Lisans/Doktora">
                        Yüksek Lisans/Doktora
                      </option>
                    </select>
                    {formik.touched.education && formik.errors.education ? (
                      <motion.div
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: "auto" }}
                        exit={{ opacity: 0, height: 0 }}
                        className="text-red-500 text-sm text-left"
                      >
                        {formik.errors.education}
                      </motion.div>
                    ) : null}
                  </motion.div>

                  <motion.div variants={formControlVariants} className="form-control">
                    <label className="label">
                      <span className="label-text">İl</span>
                    </label>
                    <input
                      type="text"
                      name="city"
                      value={formik.values.city}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="İl"
                      className={`input input-bordered w-full ${formik.touched.city && formik.errors.city ? 'input-error' : ''
                        }`}
                      required
                    />
                    {formik.touched.city && formik.errors.city ? (
                      <motion.div
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: "auto" }}
                        exit={{ opacity: 0, height: 0 }}
                        className="text-red-500 text-sm text-left"
                      >
                        {formik.errors.city}
                      </motion.div>
                    ) : null}
                  </motion.div>

                  <motion.div variants={formControlVariants} className="form-control">
                    <label className="label">
                      <span className="label-text">İlçe</span>
                    </label>
                    <input
                      type="text"
                      name="district"
                      value={formik.values.district}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="İlçe"
                      className={`input input-bordered w-full ${formik.touched.district && formik.errors.district ? 'input-error' : ''
                        }`}
                      required
                    />
                    {formik.touched.district && formik.errors.district ? (
                      <motion.div
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: "auto" }}
                        exit={{ opacity: 0, height: 0 }}
                        className="text-red-500 text-sm text-left"
                      >
                        {formik.errors.district}
                      </motion.div>
                    ) : null}
                  </motion.div>

                  <motion.div variants={formControlVariants} className="form-control">
                    <label className="label">
                      <span className="label-text">Mahalle/Sokak</span>
                    </label>
                    <input
                      type="text"
                      name="street"
                      value={formik.values.street}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Mahalle/Sokak"
                      className={`input input-bordered w-full ${formik.touched.street && formik.errors.street ? 'input-error' : ''
                        }`}
                      required
                    />
                    {formik.touched.street && formik.errors.street ? (
                      <motion.div
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: "auto" }}
                        exit={{ opacity: 0, height: 0 }}
                        className="text-red-500 text-sm text-left"
                      >
                        {formik.errors.street}
                      </motion.div>
                    ) : null}
                  </motion.div>

                  <motion.div variants={formControlVariants} className="form-control">
                    <label className="label">
                      <span className="label-text">Sektör Deneyim Süreniz (varsa)</span>
                    </label>
                    <input
                      type="text"
                      name="sectorExperience"
                      value={formik.values.sectorExperience}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Sektör Deneyim Süreniz (Örn.: 1,5 Yıl)"
                      className="input input-bordered w-full"
                    />
                  </motion.div>

                  <motion.div variants={formControlVariants} className="form-control">
                    <label className="label">
                      <span className="label-text">Marka Deneyim Süreniz (varsa)</span>
                    </label>
                    <input
                      type="text"
                      name="brandExperience"
                      value={formik.values.brandExperience}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Marka Deneyim Süreniz (Örn.: 1,5 Yıl)"
                      className="input input-bordered w-full"
                    />
                  </motion.div>

                  <motion.div variants={formControlVariants} className="form-control">
                    <label className="label">
                      <span className="label-text">Sizin için bir franchise başarısı için en önemlisi hangisi</span>
                    </label>
                    <select
                      name="importantFactor"
                      value={formik.values.importantFactor}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="select select-bordered w-full"
                      required
                    >
                      <option value="">Seçiniz</option>
                      <option value="Eğitim Desteği">Eğitim Desteği</option>
                      <option value="CRM Alt Yapısı">CRM Alt Yapısı</option>
                      <option value="Kurumsal Alt Yapı">Kurumsal Alt Yapı</option>
                      <option value="Dijital Pazarlama">Dijital Pazarlama</option>
                      <option value="Ekip Kurulum Desteği">Ekip Kurulum Desteği</option>
                    </select>
                  </motion.div>

                  <motion.div variants={formControlVariants} className="form-control">
                    <label className="label">
                      <span className="label-text">Bu gayrimenkul franchise yatırımı için ayırdığınız bir bütçe var mı?</span>
                    </label>
                    <select
                      name="budget"
                      value={formik.values.budget}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="select select-bordered w-full"
                      required
                    >
                      <option value="">Seçiniz</option>
                      <option value="1 milyon-3 milyon">1 milyon-3 milyon</option>
                      <option value="3-milyon - 5 milyon">3-milyon - 5 milyon</option>
                      <option value="5 milyon - 8 milyon">5 milyon - 8 milyon</option>
                      <option value="Cevap vermek istemiyorum">Cevap vermek istemiyorum</option>
                    </select>
                  </motion.div>

                  <motion.button
                    type="submit"
                    className={`btn btn-primary w-full mt-6`}
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                    disabled={isSubmitting}
                  >
                  <span className={`${isSubmitting ? 'loading' : ''}`}>
                    {isSubmitting ? '' : 'Başvuruyu Gönder'}</span>
                  </motion.button>
                </>
              )}
            </motion.div>
          </form>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default FranchiseForm;
