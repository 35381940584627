import React from "react";
import { motion } from "framer-motion";

const consultants = [
  {
    id: 1,
    name: "Ali Yetgin",
    title: "Yönetim Kurulu Başkanı",
    phone: "0532 120 69 09",
    email: "ahmet@example.com",
    image: "/ALI.png",
  },
  {
    id: 2,
    name: "Tolga Şirip",
    title: "Ofis Direktörü",
    phone: "0532 689 29 15",
    email: "ahmet@example.com",
    image: "/TOLGA.png",
  },
  {
    id: 3,
    name: "Nagihan Yetgin",
    title: "Gayrimenkul Danışmanı",
    phone: "0532 454 33 36",
    email: "mehmet@example.com",
    image: "/NAGIHAN.png",
  },

  {
    id: 4,
    name: "Bülent Tozo",
    title: "Gayrimenkul Danışmanı",
    phone: "0532 065 90 74",
    email: "ayse@example.com",
    image: "/BULENT.png",
  },

  {
    id: 5,
    name: "Nurten Yüksel",
    title: "Gayrimenkul Danışmanı",
    phone: "0553 145 10 50",
    email: "mehmet@example.com",
    image: "/NURTEN.png",
  },
  {
    id: 6,
    name: "Sadullah Karakaya",
    title: "Gayrimenkul Danışmanı",
    phone: "0535 729 79 10",
    email: "mehmet@example.com",
    image: "/SADULLAH.png",
  },
  {
    id: 7,
    name: "Umut Önel",
    title: "Gayrimenkul Danışmanı",
    phone: "0535 740 50 08",
    email: "mehmet@example.com",
    image: "/UMUT.png",
  },
  {
    id: 8,
    name: "MEHMET ULUK",
    title: "Gayrimenkul Danışmanı",
    phone: "0539 965 60 59",
    email: "mehmet@example.com",
    image: "/MEHMET.png",
  },
  {
    id: 9,
    name: "Serpe Simge Önel",
    title: "Broker",
    phone: "0545 212 20 00",
    email: "mehmet@example.com",
    image: "/SERPE.png",
  },
  {
    id: 10,
    name: "Saliha Aymelek",
    title: "Gayrimenkul Danışmanı",
    phone: "0507 093 15 58",
    email: "mehmet@example.com",
    image: "/SALIHA.png",
  },
  {
    id: 11,
    name: "Ömer Zeytin",
    title: "Gayrimenkul Danışmanı",
    phone: "0530 289 23 92",
    email: "mehmet@example.com",
    image: "/OMER.png",
  },
  {
    id: 12,
    name: "Betül Baştürk",
    title: "Gayrimenkul Danışmanı",
    phone: "0507 292 73 14",
    email: "mehmet@example.com",
    image: "/BETUL.png",
  },
  {
    id: 13,
    name: "Mutlu Metin",
    title: "Arsa & Arazi Uzmanı",
    phone: "0507 309 50 00",
    email: "mehmet@example.com",
    image: "/MUTLU.png",
  },
  {
    id: 14,
    name: "Arda Yıldız",
    title: "Gayrimenkul Danışmanı",
    phone: "0505 075 20 21",
    email: "mehmet@example.com",
    image: "/ARDA.png",
  },
  {
    id: 15,
    name: "Sertaç Çakar",
    title: "Gayrimenkul Danışmanı",
    phone: "0533 385 73 93",
    email: "mehmet@example.com",
    image: "/SERTAC.png",
  },
  {
    id: 16,
    name: "Hakan Hızlı",
    title: "Gayrimenkul Danışmanı",
    phone: "0541 837 89 55",
    email: "mehmet@example.com",
    image: "/HAKAN.png",
  },
  {
    id: 17,
    name: "Erdinç Makum",
    title: "Gayrimenkul Danışmanı",
    phone: "0507 747 36 47",
    email: "mehmet@example.com",
    image: "/ERDINC.png",
  },
];

const ConsultantsPage = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const cardVariants = {
    hidden: {
      opacity: 0,
      y: 50,
      scale: 0.9,
    },
    visible: {
      opacity: 1,
      y: 0,
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 12,
      },
    },
  };

  return (
    <div className="container mx-auto py-10 px-4">
      <motion.h1
        className="text-3xl font-bold text-center mb-8 tracking-wide"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        Danışmanlarımız
      </motion.h1>

      <motion.div
        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        {consultants.map((consultant) => (
          <motion.div
            key={consultant.id}
            className="card bg-base-100 shadow-xl hover:shadow-2xl transition-shadow duration-300"
            variants={cardVariants}
          >
            <motion.figure
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.15, delay: 0.1 }}
            >
              <img
                src={consultant.image}
                alt={consultant.name}
                className="w-full h-full object-contain"
              />
            </motion.figure>
            <motion.div
              className="card-body"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.3 }}
            >
              <h2 className="card-title">{consultant.name}</h2>
              <p>
                <span className="font-bold"></span>
                {consultant.title}
              </p>
              <motion.p transition={{ type: "spring", stiffness: 300 }}>
                <span className="font-bold">Telefon: </span>
                {consultant.phone}
              </motion.p>
              {/* <p>
                <span className="font-bold">E-posta: </span>
                {consultant.email}
              </p>*/}
              <div className="card-actions ">
                {/*<a
                  href={`mailto:${consultant.email}`}
                  className="btn btn-primary btn-sm"
                >
                  E-posta Gönder
                </a>*/}
                <a
                  href={`tel:+9${consultant.phone.replace(/[^0-9]/g, "")}`}
                  className="btn btn-primary btn-sm "
                >
                  Ara
                </a>
              </div>
            </motion.div>
          </motion.div>
        ))}
      </motion.div>
    </div>
  );
};

export default ConsultantsPage;
