import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const Header = () => {
  const [isHomePage, setIsHomePage] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setIsHomePage(location.pathname === "/");
  }, [location]);

  return (
    <>
      {/* Navbar */}
      <div className="navbar z-10 px-8 py-4 flex items-center justify-between bg-white text-gkBlack shadow-md">
        {/* Mobil Hamburger Butonu (Solda) */}
        <button
          className="md:hidden text-3xl"
          onClick={() => setIsSidebarOpen(true)}
        >
          ☰
        </button>

        {/* Logo (Ortada) */}
        <div className="flex-1 flex justify-center md:justify-start">
          <Link to="/" className="normal-case">
            <img
              src="/shadowed.png"
              alt="Golden Keys Real Estate Logo"
              className="h-16"
            />
          </Link>
        </div>

        {/* Masaüstü Menü */}
        <div className="hidden md:flex">
          <ul className="flex space-x-6 text-gray-700">
            <li>
              <Link to="/ilanlar" className="hover:text-gold">
                İlanlar
              </Link>
            </li>
            <li>
              <Link to="/danismanlar" className="hover:text-gold">
                Danışmanlarımız
              </Link>
            </li>
            <li>
              <Link to="/subeler" className="hover:text-gold">
                Şubelerimiz
              </Link>
            </li>
            <li>
              <Link to="/hakkimizda" className="hover:text-gold">
                Hakkımızda
              </Link>
            </li>
            <li>
              <Link to="/ilanEkle" className="hover:text-gold"></Link>
            </li>
          </ul>
        </div>
      </div>

      {/* Mobil Sidebar */}

      <div
        className={`fixed top-0 pt-2 pl-4 left-0 w-64 h-full bg-white shadow-xl transform ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 z-50`}
      >
        <Link to="/">
          <img
            src="/shadowed.png"
            alt="Golden Keys Real Estate Logo"
            className="h-14"
          />
        </Link>
        <button
          className="absolute top-4 right-4 text-2xl"
          onClick={() => setIsSidebarOpen(false)}
        >
          ✕
        </button>

        <ul className="pl-2 pt-10 space-y-4 text-lg">
          <Link
            to="/"
            className="hover:text-gold"
            onClick={() => setIsSidebarOpen(false)}
          >
            Ana Sayfa
          </Link>
          <li>
            <Link
              to="/ilanlar"
              className="hover:text-gold"
              onClick={() => setIsSidebarOpen(false)}
            >
              İlanlar
            </Link>
          </li>
          <li>
            <Link
              to="/danismanlar"
              className="hover:text-gold"
              onClick={() => setIsSidebarOpen(false)}
            >
              Danışmanlarımız
            </Link>
          </li>
          <li>
            <Link
              to="/subeler"
              className="hover:text-gold"
              onClick={() => setIsSidebarOpen(false)}
            >
              Şubelerimiz
            </Link>
          </li>
          <li>
            <Link
              to="/hakkimizda"
              className="hover:text-gold"
              onClick={() => setIsSidebarOpen(false)}
            >
              Hakkımızda
            </Link>
          </li>
        </ul>
      </div>

      {/* Sidebar Açıldığında Arka Planı Kapatma */}
      {isSidebarOpen && (
        <div
          className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-40"
          onClick={() => setIsSidebarOpen(false)}
        ></div>
      )}
    </>
  );
};

export default Header;
