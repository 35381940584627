import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import {
  MapPinIcon,
  ArrowsPointingOutIcon,
  HomeIcon,
} from "@heroicons/react/24/solid";

const ListingsPage = () => {
  const [properties, setProperties] = useState([]);
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [filters, setFilters] = useState({
    price: "",
    rooms: "",
    category: "",
  });
  const [sortOption, setSortOption] = useState("");

  useEffect(() => {
    const fetchedProperties = [
      {
        id: 1,
        title: "BERGAMA G.O.P MAH MÜSTAKİL TADINDA 3+1 DAİRE",
        price: 3200000,
        rooms: "3+1",
        size: "135 m²",
        location: "İzmir / Bergama",
        category: "Konut",
        image: "/ev1.png",
        status: "/1.png",
      },
      {
        id: 2,
        title: "BERGAMA BAHÇELİEVLER MH ILICA MEVKİİ ULTRA GENİŞ ARAKAT",
        price: 5050000,
        rooms: "3+1",
        size: "160 m²",
        location: "İzmir / Bergama",
        category: "Arsa",
        image: "/ev2.png",
        status: "/2.png",
      },
      {
        id: 3,
        title: "DAP YAPI 2+1 SATILIK BAHÇELİ DAİRE GOLDEN KEYS REAL ESTATE",
        price: 7500000,
        rooms: "3+1",
        size: "168 m²",
        location: "İzmir / Bornova",
        category: "İş Yeri",
        image: "ev3.png",
        status: "/3.png",
      },
    ];
    setProperties(fetchedProperties);
    setFilteredProperties(fetchedProperties);
  }, []);

  useEffect(() => {
    let filtered = [...properties];

    if (filters.price) {
      filtered = filtered.filter((property) => property.price <= filters.price);
    }
    if (filters.rooms) {
      filtered = filtered.filter((property) => property.rooms >= filters.rooms);
    }
    if (filters.category) {
      filtered = filtered.filter(
        (property) => property.category === filters.category
      );
    }

    if (sortOption === "price_asc") {
      filtered = filtered.sort((a, b) => a.price - b.price);
    } else if (sortOption === "price_desc") {
      filtered = filtered.sort((a, b) => b.price - a.price);
    } else if (sortOption === "rooms_desc") {
      filtered = filtered.sort((a, b) => b.rooms - a.rooms);
    }

    setFilteredProperties(filtered);
  }, [filters, sortOption, properties]);

  return (
    <div className="px-6 py-4">
      <div className="mb-4">
        <input
          type="number"
          placeholder="Max Fiyat"
          value={filters.price}
          onChange={(e) => setFilters({ ...filters, price: e.target.value })}
          className="input input-bordered"
        />
        <input
          type="number"
          placeholder="Min Oda Sayısı"
          value={filters.rooms}
          onChange={(e) => setFilters({ ...filters, rooms: e.target.value })}
          className="input input-bordered ml-4"
        />
        <select
          value={filters.category}
          onChange={(e) => setFilters({ ...filters, category: e.target.value })}
          className="select select-bordered ml-4"
        >
          <option value="">Kategori Seç</option>
          <option value="Konut">Konut</option>
          <option value="Arsa">Arsa</option>
          <option value="İş Yeri">İş Yeri</option>
        </select>

        <select
          value={sortOption}
          onChange={(e) => setSortOption(e.target.value)}
          className="select select-bordered ml-4"
        >
          <option value="">Sırala</option>
          <option value="price_asc">Fiyat (Artan Sırada)</option>
          <option value="price_desc">Fiyat (Azalan Sırada)</option>
          <option value="rooms_desc">Oda Sayısı (Azalan Sırada)</option>
        </select>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {filteredProperties.map((property) => (
          <div key={property.id} className="card shadow-lg relative">
            {/* Görsel */}
            <div className="relative">
              <img
                src={property.image}
                alt={property.title}
                className="card-img-top w-full h-48 object-cover"
              />
              {/* Etiket 
            <div className="badge badge-primary absolute top-2 left-2 text-2xl">
              {property.status}
            </div>*/}
              <div className="absolute inset-0 flex items-center justify-center">
                <div className="bg-gray-950/40 w-full h-16 flex items-center justify-center ">
                  <img
                    src={property.status} // Your dynamic logo based on status
                    alt={`${property.status} Logo`}
                    className="h-40" // Adjust the size of the logo
                  />
                </div>
              </div>
            </div>

            {/* Kartın Gövde Kısmı */}
            <div className="card-body">
              <h5 className="card-title text-lg font-bold text-gray-800">
                {new Intl.NumberFormat("tr-TR").format(property.price)} TL
              </h5>
              <p className="text-sm text-gray-600 mb-2">{property.title}</p>

              {/* Özellikler */}
              <ul className="flex items-center text-sm text-gray-700 space-x-6">
                <li className="flex items-center">
                  <MapPinIcon className="w-5 h-5 text-blue-500 mr-2" />
                  {property.location}
                </li>
                <li className="flex items-center">
                  <ArrowsPointingOutIcon className="w-5 h-5 text-green-500 mr-2" />
                  {property.size}
                </li>
                <li className="flex items-center">
                  <HomeIcon className="w-5 h-5 text-purple-500 mr-2" />
                  {property.rooms}
                </li>
              </ul>
              <Link
                to={`/ilanlar/${property.id}`}
                className="btn btn-primary mt-4"
              >
                Detaylar
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ListingsPage;
