import React, { useState } from "react";
import { useParams } from "react-router-dom";

const PropertyDetail = () => {
  const { id } = useParams();

  // Örnek emlak verisi (gerçek bir API'den alınabilir)
  const property = {
    id,
    title: `Konut ${id}`,
    category: "Satılık Daire",
    price: 2950000,
    location: "İzmir / Bergama / Ertuğrul Mh.",
    description:
      "Bu mülk harika bir yaşam alanı sunuyor. Modern tasarımı ve geniş alanlarıyla çok konforlu. Çok merkezi bir konumda yer alıyor.",
    details: {
      "İlan No": "1171125931",
      "İlan Tarihi": "15 Aralık 2024",
      "Emlak Tipi": "Satılık Daire",
      "Brüt m²": 150,
      "Net m²": 135,
      "Oda Sayısı": "3+1",
      "Bina Yaşı": "16-20 arası",
      "Bulunduğu Kat": 4,
      "Kat Sayısı": 5,
      Isıtma: "Kombi (Doğalgaz)",
      "Banyo Sayısı": 1,
      Mutfak: "Kapalı",
      Balkon: "Var",
      Asansör: "Var",
      Otopark: "Yok",
      Eşyalı: "Hayır",
      "Kullanım Durumu": "Kiracılı",
      "Site İçinde": "Hayır",
      "Site Adı": "Belirtilmemiş",
      Aidat: "Belirtilmemiş",
      "Krediye Uygun": "Evet",
      "Tapu Durumu": "Kat Mülkiyetli",
      Kimden: "Emlak Ofisinden",
      Takas: "Hayır",
    },
    images: ["/image1-1.jpeg", "/image1-2.jpg"],
    consultant: {
      name: "Ahmet Yılmaz",
      phone: "555-123-4567",
      email: "ahmet.yilmaz@emlakofisi.com",
      profilePicture: "/umut.png",
    },
  };

  // Ana görsel durumu
  const [mainImage, setMainImage] = useState(property.images[0]);

  return (
    <div className="container mx-auto px-4 py-6">
      <div className="flex flex-col md:flex-row gap-6">
        {/* Sol taraf: Resimler */}
        <div className="md:w-1/2">
          <img
            src={mainImage}
            alt={property.title}
            className="w-full h-80 object-cover shadow-md"
          />
          <div className="mt-4 flex space-x-2 overflow-x-auto">
            {property.images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`image-${index}`}
                className={`w-24 h-24 object-cover cursor-pointer ${
                  mainImage === image ? "ring-2 ring-gold-500" : ""
                }`}
                onClick={() => setMainImage(image)}
              />
            ))}
          </div>
          {/* Danışman bilgileri */}

          <div className="mt-6 p-4  bg-white shadow-sm border">
            <div className="flex items-end gap-6">
              <img
                src={property.consultant.profilePicture}
                alt={property.consultant.name}
                className="w-16 h-16 object-cover "
              />
              <div>
                <h3 className="text-lg font-semibold">
                  {property.consultant.name}
                </h3>
                <p className="text-gray-600 text-sm">
                  {property.consultant.email}
                </p>
                <p className="text-gray-600 text-sm">
                  {property.consultant.phone}
                </p>
              </div>
              <a
                href={`tel:+9${property.consultant.phone.replace(
                  /[^0-9]/g,
                  "",
                )}`}
                className="btn btn-primary btn-sm  "
              >
                Ara
              </a>
            </div>
          </div>
        </div>

        {/* Sağ taraf: Detaylar */}
        <div className="md:w-1/2 p-6 bg-white shadow-sm border">
          <h1 className="text-3xl font-bold mb-2">{property.title}</h1>
          <p className="text-lg text-gray-600">{property.location}</p>
          <p className="text-2xl font-semibold text-gold-600 mt-3">
            {property.price.toLocaleString()} TL
          </p>
          <p className="text-gray-700 mt-4">{property.description}</p>

          {/* Detaylar */}
          <div className=" mt-6">
            <h2 className="text-xl font-semibold mb-4">Emlak Detayları</h2>
            <div className="flex w-full">
              <ul className="grid grid-cols-1 gap-4">
                {Object.entries(property.details)
                  .filter((value, i) => i % 2 === 0)
                  .map(([key, value]) => (
                    <li key={key} className="flex justify-between text-sm">
                      <span className="font-medium text-gray-600">{key}:</span>
                      <span className="text-gray-800">{value}</span>
                    </li>
                  ))}
              </ul>
              <div className="divider divider-horizontal"></div>
              <ul className="grid grid-cols-1 gap-4">
                {Object.entries(property.details)
                  .filter((value, i) => i % 2 === 1)
                  .map(([key, value]) => (
                    <li key={key} className="flex justify-between text-sm">
                      <span className="font-medium text-gray-600">{key}:</span>
                      <span className="text-gray-800">{value}</span>
                    </li>
                  ))}
              </ul>
            </div>
          </div>

          <button className="btn btn-primary mt-6 w-full">İletişime Geç</button>
        </div>
      </div>
    </div>
  );
};

export default PropertyDetail;
