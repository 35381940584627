// src/components/HeroSection.js

import React from "react";

const MainSection = () => {
  return (
    <div className="relative h-96">
      <div className="hero h-32">
        <div className="hero-content text-center text-[#0D0D0D] bg-transparent z-10 relative">
          <div className="max-w-md">
            <h1 className="mb-5 text-5xl font-bold">
              Gayrimenkul ihtiyaçlarınız için çözüm noktanız
            </h1>
            <p className="mb-5 text-lg ">
              En iyi gayrimenkul seçenekleri, sektördeki tecrübemiz ve kaliteli
              hizmet anlayışımız ile yanınızdayız.
            </p>
            <a href="#highlights" className="btn btn-primary">
              Daha Fazla Bilgi
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainSection;
