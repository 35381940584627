import React from "react";

const offices = [
  {
    name: "İzmir/Merkez Ofisi",
    location: "Çiğli",
    description:
      "Ege Bölgesi'nin en hızlı gelişen şehri. Yatırım fırsatları için ideal.",
    image: "/image4.jpg",
    url: "https://goldenkeysrealestate.sahibinden.com/",
  },
  {
    name: "Bergama Ofisi",
    location: "Bergama",
    description:
      "Bergama, tarihi ve doğal güzellikleriyle öne çıkıyor. Yatırımlarınız için ideal bir bölge.",
    image: "/image5.jpg",
    url: "https://goldenkeysrealestatebergama.sahibinden.com/",
  },
  {
    name: "Aliağa Premium Ofis",
    location: "Aliağa",
    description:
      "Aliağa, sanayi ve lojistik sektörünün merkezlerinden biridir.",
    image: "/image3.jpg",
    url: "https://goldenkeysrealestate.sahibinden.com/",
  },
];

const Offices = () => {
  return (
    <section id="offices" className="py-16">
      <h2 className="text-center text-3xl font-bold mb-8">Ofislerimiz</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {offices.map((office, index) => (
          <div
            key={index}
            className="card bg-base-100 shadow-xl transition duration-300 ease-in-out"
          >
            <figure>
              <img
                src={office.image}
                alt={office.name}
                className="w-full h-48 object-cover"
              />
            </figure>
            <div className="card-body">
              <h2 className="card-title">{office.name}</h2>
              <p className="text-sm text-gray-500">{office.location}</p>
              <p>{office.description}</p>
              <a
                href={office.url} // Her ofisin URL'si buradan alınır
                target="_blank" // Yeni sekmede açılması için
                rel="noopener noreferrer" // Güvenlik için
                className="btn btn-primary mt-4"
              >
                Detaylar
              </a>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Offices;
