import ScrollToTop from "./components/ScrollToTop";
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import Header from "./components/Header";
import HomePage from "./pages/HomePage";
import ListingsPage from "./pages/ListingsPage";
import PropertyDetailPage from "./pages/PropertyDetailPage";
import "./index.css";
import Footer from "./components/Footer";
import ConsultantsPage from "./pages/ConsultantsPage";
import AboutPage from "./pages/AboutPage";
import OfficesPage from "./pages/OfficesPage";
import AddConsultantsPage from "./pages/AddConsultantsPage";

function App() {
  return (
    <div className="App bg-ivory font-lato overflow-x-hidden">
      <Router>
        <Header />
        <MainContent />
        <Footer />
      </Router>
    </div>
  );
}

const MainContent = () => {
  const location = useLocation();

  const pageVariants = {
    initial: {
      opacity: 0,
      x: -200,
    },
    animate: {
      opacity: 1,
      x: 0,
      transition: {
        type: "spring",
        damping: 20,
        stiffness: 100,
      },
    },
    exit: {
      opacity: 0,
      x: 200,
      transition: {
        duration: 0.3,
      },
    },
  };

  return (
    <div>
      <ScrollToTop />
      <AnimatePresence mode="wait">
        <motion.div
          key={location.pathname}
          initial="initial"
          animate="animate"
          exit="exit"
          variants={pageVariants}
        >
          <Routes location={location}>
            <Route path="/" element={<HomePage />} />
            <Route path="/ilanlar" element={<ListingsPage />} />
            <Route path="/ilanlar/:id" element={<PropertyDetailPage />} />
            <Route path="/danismanlar" element={<ConsultantsPage />} />
            <Route path="/hakkimizda" element={<AboutPage />} />
            <Route path="/subeler" element={<OfficesPage />} />
            <Route path="/ilanEkle" element={<AddConsultantsPage />} />
          </Routes>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default App;
