import React, { useEffect, useRef } from "react";

const CarouselSection = () => {
  const videoRef = useRef(null);

  // Video hızını ayarlamak için useEffect
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 1;
      videoRef.current.play(); // Video başlatma işlemi mobil cihazlarda zorunlu olabilir
    }
  }, []);

  return (
    <div className="h-[800px] w-full overflow-hidden relative before:content-[''] before:bg-gkBlack/50 before:w-full before:h-full before:absolute before:left-0 before:top-0 before:z-[10]">
      {/* Video */}
      <video
        ref={videoRef}
        className="relative top-0 left-0 w-full h-full object-cover "
        src="/EmlakLogo.mp4" // Video dosyasının yolu
        autoPlay
        loop
        muted
        playsInline // Mobilde videonun tam ekran olmadan oynatılmasını sağlar
      ></video>

      <div
        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full max-w-full h-[30%]  flex flex-col p-6 z-10" // Mobil için daha fazla padding üstte
      >
        <img
          src="/GK_yatay_zeminli.svg"
          alt="Logo"
          className="absolute top-0 left-0 md:left-1/2 z-10 transform -translate-y-44 md:-translate-x-1/2 md:-translate-y-1/2"
          style={{ width: "100vw", maxWidth: "1200px", minWidth: "400px" }} // vw ile logo boyutlandırma
        />
      </div>
    </div>
  );
};

export default CarouselSection;
