import React from "react";

const Highlights = () => {
  return (
    <section id="highlights" className="grid grid-cols-1 md:grid-cols-3 gap-20">
      {/* Hayalinizdeki Evi Bulun */}
      <div className="card bg-base-100 shadow-xl transition duration-300 ease-in-out">
        <figure className="p-5">
          <img src="1.svg" alt="Ev" className="w-20 h-20" />
        </figure>
        <div className="card-body">
          <h2 className="card-title">Hayalinizdeki Evi Bulun</h2>
          <p>
            Şimdi aramaya başlayın, size en uygun seçenekleri keşfedin. Modern,
            güvenli ve keyifli yaşam alanları.
          </p>
        </div>
      </div>

      {/* Yatırım Yapmanın Tam Zamanı */}
      <div className="card bg-base-100 shadow-xl transition duration-300 ease-in-out">
        <figure className="p-5">
          <img src="2.svg" alt="Yatırım" className="w-20 h-20" />
        </figure>
        <div className="card-body">
          <h2 className="card-title">Yatırım Yapmanın Tam Zamanı</h2>
          <p>
            Değerlenen bölgelerde fırsatları kaçırmayın. Yatırımınızın getirisi
            yüksek olsun!
          </p>
        </div>
      </div>

      {/* Profesyonel Danışmanlık */}
      <div className="card bg-base-100 shadow-xl transition duration-300 ease-in-out">
        <figure className="p-5">
          <img src="3.svg" alt="Danışmanlık" className="w-20 h-20" />
        </figure>
        <div className="card-body">
          <h2 className="card-title">Profesyonel Danışmanlık</h2>
          <p>
            Tecrübeli ekibimizle size özel çözümler sunuyoruz. İlk adımı
            birlikte atalım.
          </p>
        </div>
      </div>

      {/* Öne Çıkan Gayrimenkuller */}
      <div className="card bg-base-100 shadow-xl transition duration-300 ease-in-out">
        <figure className="p-5">
          <img src="4.svg" alt="Gayrimenkul" className="w-20 h-20" />
        </figure>
        <div className="card-body">
          <h2 className="card-title">Öne Çıkan Gayrimenkuller</h2>
          <p>En gözde konumlarda özenle seçilmiş seçenekler. Hemen göz atın!</p>
        </div>
      </div>

      {/* Yeni Projeler */}
      <div className="card bg-base-100 shadow-xl transition duration-300 ease-in-out">
        <figure className="p-5">
          <img src="6.svg" alt="Projeler" className="w-20 h-20" />
        </figure>
        <div className="card-body">
          <h2 className="card-title">Yeni Projeler</h2>
          <p>
            Modern mimaride konforlu yaşam alanları. İlk sahiplerinden olun!
          </p>
        </div>
      </div>

      {/* Müşteri Memnuniyeti */}
      <div className="card bg-base-100 shadow-xl transition duration-300 ease-in-out">
        <figure className="p-5">
          <img src="5.svg" alt="Müşteri Memnuniyeti" className="w-20 h-20" />
        </figure>
        <div className="card-body">
          <h2 className="card-title">Müşteri Memnuniyeti</h2>
          <p>
            Siz de mutlu müşterilerimiz arasında yer alın. Güvenilir hizmet için
            buradayız.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Highlights;
