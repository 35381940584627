import React, { useState } from "react";
import { motion } from "framer-motion";
import FranchiseForm from "./FranchiseForm";

const FranciseInfo = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div className="absolute w-full -top-[500px] md:-top-72 text-center">
      <div className="bg-none shadow-lg px-2 py-4 w-full max-w-5xl border-[1px] border-white z-20 text-center m-auto">
        <p className="text-white text-lg text-center">
          BÖLGENİZİN ALTIN ANAHTARINA SAHİP OLMAK VE OFİS AÇMAK İÇİN{" "}
          <span className="font-bold text-gold"> FRANCHISE </span>
          BAŞVURU FORMUNU DOLDURABİLİRSİNİZ!
        </p>
        <motion.button
          className="btn btn-primary hover:!text-white transition duration-300 ease-in-out transform uppercase mt-2"
          initial={{ opacity: 0, y: 0 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          onClick={openModal} // Modal açma fonksiyonu
        >
          Başvuru Formu
        </motion.button>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 overflow-y-auto">
          <FranchiseForm closeModal={closeModal} />
        </div>
      )}
    </div>
  );
};

export default FranciseInfo;
