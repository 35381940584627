import axios from 'axios';
import { apiUrl } from '../config/config';
import { handleApiError } from '../utils/errorHandler';

const franchiseApi = axios.create({
  baseURL: apiUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add request interceptor for logging in development
franchiseApi.interceptors.request.use(request => {
  if (process.env.NODE_ENV === 'development') {
    console.log('Starting Request:', request);
  }
  return request;
});

// Add response interceptor for logging in development
franchiseApi.interceptors.response.use(
  response => {
    if (process.env.NODE_ENV === 'development') {
      console.log('Response:', response);
    }
    return response;
  },
  error => {
    if (process.env.NODE_ENV === 'development') {
      console.error('Response Error:', error);
    }
    return Promise.reject(error);
  }
);

export const submitFranchiseForm = async (formData) => {
  try {
    const response = await franchiseApi.post('/franchises', {
      ...formData,
      submittedAt: new Date().toISOString(),
    });
    return response.data;
  } catch (error) {
    const handledError = handleApiError(error);
    throw handledError;
  }
};