import React from "react";
import { Link } from "react-router-dom";

const PortfolioSection = () => {
  return (
    <section className="relative shadow-sm border border-neutral-next-100 bg-white overflow-hidden ">
      <div
        className="h-[400px] w-[700px] bg-cover bg-center bg-no-repeat "
        style={{ backgroundImage: "url('/imageHouse.jpg')" }}
      ></div>
      <div className="absolute inset-0 bg-black opacity-40 lg:opacity-0"></div>
      <div className="absolute top-20 max-md:left-7 md:right-10 text-gkBlack text-left">
        <div className="max-w-md">
          <h1 className="mb-5 text-5xl font-bold text-white lg:text-black">
            Geniş Portföy Ağımız
          </h1>
          <p className="mb-5 text-lg text-white lg:text-black">
            Sizin için en gözde lokasyonlarda, her bütçeye uygun seçeneklerle
            öne çıkan ilanlarımızı keşfedin. Hızlıca bakın, hayalinizdeki ev
            şimdi bir tık uzağınızda!"
          </p>
          <Link to="/ilanlar" className="btn btn-primary">
            <p>İlanlara Gözat</p>{" "}
          </Link>
        </div>
      </div>
    </section>
  );
};

export default PortfolioSection;
