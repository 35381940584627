// src/pages/PropertyDetailPage.js
import React from "react";
import PropertyDetail from "../components/PropertyDetail.jsx";
const PropertyDetailPage = () => {
  return (
    <div>
      <PropertyDetail />
    </div>
  );
};
export default PropertyDetailPage;
