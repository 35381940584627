export const handleApiError = (error) => {
  if (error.response) {
    // Server responded with a status code outside of 2xx range
    return {
      message: error.response.data.message || 'Sunucu hatası oluştu',
      status: error.response.status
    };
  } else if (error.request) {
    // Request was made but no response received
    return {
      message: 'Sunucuya ulaşılamıyor. Lütfen internet bağlantınızı kontrol edin.',
      status: 0
    };
  } else {
    // Something happened in setting up the request
    return {
      message: 'İstek oluşturulurken bir hata meydana geldi.',
      status: 0
    };
  }
};