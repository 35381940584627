import React, { useState } from "react";

const AddConsultantsPage = () => {
  const [formData, setFormData] = useState({
    title: "",
    price: "",
    location: "",
    propertyType: "Satılık",
    netArea: "",
    buildingAge: "",
    floorCount: "",
    bathroomCount: "",
    balcony: "Var",
    parking: "Yok",
    usageStatus: "Boş",
    siteName: "",
    isCreditEligible: "Evet",
    sellerType: "Emlak Ofisinden",
    listingDate: "",
    grossArea: "",
    roomCount: "",
    floor: "Giriş",
    heating: "Kombi (Doğalgaz)",
    kitchen: "Kapalı",
    elevator: "Var",
    furnished: "Hayır",
    isInsideSite: "Hayır",
    dues: "",
    deedStatus: "Kat Mülkiyetli",
    barter: "Hayır",
    photos: [],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setFormData({ ...formData, photos: files });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Data Submitted:", formData);
    // Backend'e gönderme işlemi burada yapılabilir
  };

  return (
    <div className="container mx-auto py-10">
      <h1 className="text-3xl font-bold text-center mb-6">İlan Ekle</h1>
      <form
        className="space-y-4 bg-white p-6 shadow-md"
        onSubmit={handleSubmit}
      >
        <div className="form-control">
          <label className="label">İlan Başlığı</label>
          <input
            type="text"
            name="title"
            value={formData.title}
            onChange={handleChange}
            className="input input-bordered"
            placeholder="İlan başlığı girin"
          />
        </div>

        <div className="form-control">
          <label className="label">Fiyat</label>
          <input
            type="number"
            name="price"
            value={formData.price}
            onChange={handleChange}
            className="input input-bordered"
            placeholder="Emlak fiyatı girin"
          />
        </div>

        <div className="form-control">
          <label className="label">Konum</label>
          <input
            type="text"
            name="location"
            value={formData.location}
            onChange={handleChange}
            className="input input-bordered"
            placeholder="Emlak konumu girin"
          />
        </div>

        <div className="form-control">
          <label className="label">Emlak Tipi</label>
          <select
            name="propertyType"
            value={formData.propertyType}
            onChange={handleChange}
            className="select select-bordered"
          >
            <option>Satılık</option>
            <option>Kiralık</option>
          </select>
        </div>

        <div className="form-control">
          <label className="label">Net m²</label>
          <input
            type="number"
            name="netArea"
            value={formData.netArea}
            onChange={handleChange}
            className="input input-bordered"
            placeholder="Net m² girin"
          />
        </div>

        {/* Diğer alanlar */}
        {[
          { label: "Bina Yaşı", name: "buildingAge", type: "number" },
          { label: "Kat Sayısı", name: "floorCount", type: "number" },
          { label: "Banyo Sayısı", name: "bathroomCount", type: "number" },
          { label: "Site Adı", name: "siteName", type: "text" },
          { label: "Brüt m²", name: "grossArea", type: "number" },
          { label: "Oda Sayısı", name: "roomCount", type: "text" },
          { label: "Aidat", name: "dues", type: "text" },
        ].map((field, index) => (
          <div className="form-control" key={index}>
            <label className="label">{field.label}</label>
            <input
              type={field.type}
              name={field.name}
              value={formData[field.name]}
              onChange={handleChange}
              className="input input-bordered"
              placeholder={`${field.label} girin`}
            />
          </div>
        ))}

        {/* Fotoğraf Yükleme */}
        <div className="form-control">
          <label className="label">Emlak Fotoğrafları</label>
          <input
            type="file"
            multiple
            onChange={handleFileChange}
            className="file-input file-input-bordered"
          />
        </div>

        <button type="submit" className="btn btn-primary w-full">
          İlanı Ekle
        </button>
      </form>
    </div>
  );
};

export default AddConsultantsPage;
